/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Navbar from './HealthProviderNavbar';
import AboutPage from '../AboutPage';
import HelpPage from '../HelpPage';
import Sidebar from '../Shared/Sidebar';
import HealthProviderDashboard from './HealthProviderDashboard';
import PatientPanel from './PatientPanel';
import HuddleDetails from '../Shared/Huddle/HuddleDetails';
import HuddleHistory from '../Shared/Huddle/HuddleHistory';
import PermissionService from '../../Services/PermissionService';
import EnrollPatient from './EnrollPatient';
import DataReadingsManager from '../Shared/DataReadings/DataReadingsManager';
import PathwaySettings from './PathwaySettings';
import AddVitalData from './AddVitalData';
import './HealthProviderContainer.css';
import PatientInformation from './PatientInformation';
import PatientInformationEdit from './PatientInformationEdit';
import PatientInformationVitalsHistory from './PatientInformationVitalsHistory';
import MyProfile from './MyProfile';
import { Container, Row, Col } from 'react-bootstrap/';
import PatientVitalThresholds from './PatientVitalThresholds';
import EditTeamlet from '../Shared/Teamlets/EditTeamlet';
import Timeout from '../Shared/Timeout';
import TeamletDetails from '../Shared/Teamlets/TeamletDetails';
import AddHuddle from '../Huddles/Huddle';
import AdminConfiguration from '../Administrator/AdminConfiguration';
import PatientRegistrationView from '../Shared/PatientRegistration/PatientRegistrationView';
import PatientRegistrationSelect from '../Shared/PatientRegistration/PatientRegistrationSelect';
import PatientRegistrationAdd from '../Shared/PatientRegistration/PatientRegistrationAdd';
import RPMdeviceModals from '../Administrator/RPMdeviceModels';
const config = require('../../config.json');

interface CustomLocation {
    username: string,
    role: string,
    userOrganizations: any,
    displayRoleName: string,
    parentOrgID: string,
    parentOrgName: string,
    permissions: any,
    myInternalID : string,
    isEHROrganization: boolean,
}


function HealthProviderContainer() {
    const [permissionService, setPermissionService] = useState(new PermissionService());
    const location = useLocation().state as CustomLocation;
    const [roleName, setRoleName] = useState("");
    const [displayRoleName, setDisplayRoleName] = useState("");
    const [username, setUsername] = useState("");
    const [logout, setLogout] = useState(false);
    //const [viewMode, setViewMode] = useState(sessionStorage.getItem('restorePage') ? sessionStorage.getItem('restorePage') : "dashboard");
    const [viewMode, setViewMode] = useState("dashboard");
    const [sidebarExpanded, setSidebarExpanded] = useState(true);
    const [patientID, setPatientID] = useState("");

    //These fields track the organization info dropdown on the navbar that effects the page
    const [organizationList, setOrganizationList] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState("");
    const [selectedOrganizationID, setSelectedOrganizationID] = useState("");
    const [parentOrgName, setParentOrgName] = useState("");
    const [parentOrgID, setParentOrgID] = useState("");

    //Fields for editing a teamlet
    const [teamletName, setTeamletName] = useState("");
    const [parentID, setParentID] = useState("");
    const [parentName, setParentName] = useState("");
    const [organization, setOrganization] = useState("");
    const [organizationID, setOrganizationID] = useState("");
    const [site, setSite] = useState("");
    const [pathwayList, setPathwayList] = useState<any[]>([]);
    const [memberList, setMemberList] = useState<any[]>([]);
    const [healthCoachCount, setHealthCoachCount] = useState(0);
    const [providerCount, setProviderCount] = useState(0);
    const [teamletID, setTeamletID] = useState("");

    const [huddleID, setHuddleID] = useState('');
    const [meetingID, setMeetingID] = useState('');
    const [returnTo, setReturnTo] = useState('dashboard');

    useEffect(() => {
        //Load the correct page upon refresh
        /*if (sessionStorage.getItem('restorePage')) {
            setViewMode(sessionStorage.getItem('restorePage') || "");
        }*/

        // Check if we have location.  If we dont have location from a new tab or manually entered URL we redirect to login.
        if (location) {
            setUsername(location.username);
            setRoleName(location.role);
            permissionService.loadUserOrgs(location.userOrganizations);
            setDisplayRoleName(location.displayRoleName);
            setParentOrgID(location.parentOrgID);
            setParentOrgName(location.parentOrgName);
            permissionService.setMyInternalID(location.myInternalID);
            permissionService.loadPermissions(location.permissions);
            permissionService.setIsEHROrganization(location.isEHROrganization);
        } else {
            setLogout(true);
        }

    }, [location, selectedOrganization]);

    useEffect(() => {
        if (location) {
            setSelectOrganization(location.userOrganizations[0].value, location.userOrganizations[0].name);
        }
    }, []);

    function switchView(newView: string) {
        setViewMode(newView);
    }

    function enrollPatient() {
        setViewMode("enrollPatient");
    }

    function expandCollapseSidebar() {
        setSidebarExpanded(!sidebarExpanded);
    }

    /**
     * @name setParentOrgId
     * @param ParentId 
     */
    function setSelectedPatientID(ParentId: string) {
        setPatientID(ParentId);
    }

    /**
     * @name setSelectedTeamletID
     * @param ParentId 
     */
    function setSelectedTeamletID(TeamLetID: string) {
        setTeamletID(TeamLetID);
    }

    /**
     * @name setSelectedOrganizationName
     * @param ParentId 
     */
    function setSelectedOrganizationName(OrgName: string) {
        setSelectedOrganization(OrgName);
    }


    //  Used in navbar props
    function setSelectOrganization(orgID: string, orgName: string) {
        setSelectedOrganization(orgName);
        setSelectedOrganizationID(orgID);

        /*  Let leave this here until we know the fix works
        currentView = <HealthProviderDashboard switchView={switchView}
                            selectedOrganization={selectedOrganization}
                            selectedOrganizationID={selectedOrganizationID} />
                            */
    }

    function setSelectedHuddleID(newID: string) {
        setHuddleID(newID);
    }


    /**
     * @name setReturnToView
     * @description This function is used to set the return to view from Edit Teamlet
     * 
    */
    function setReturnToView(viewame: string) {
        setReturnTo(viewame);
    }

    /**
     * @name editTeamlet
     * @description This function is passed down through props so the EditTeamlet.  Used for tracking the
     * fields to be passed down
     */
    function editTeamlet(tempTeamletName: string, tempParentName: string, tempParentID: string,
        tempOrganization: string, tempOrganizationID: string, tempSite: string, tempPathwayList: any[],
        tempMemberList: any[], tempHealthCoachCount: number, tempProviderCount: number, tempTeamletID: string) {

        setTeamletName(tempTeamletName);
        setParentID(tempParentID);
        setParentName(tempParentName);
        setOrganization(tempOrganization);
        setOrganizationID(tempOrganizationID);
        setSite(tempSite);
        setPathwayList(tempPathwayList);
        setMemberList(tempMemberList);
        setHealthCoachCount(tempHealthCoachCount);
        setProviderCount(tempProviderCount);
        setTeamletID(tempTeamletID);
    }

    /**
    * @name Logout
    * @description Logouts out the User
    * @param
    * @returns {json}
    */
    async function Logout() {
        const token = sessionStorage.getItem('ACTIVATEtoken');
        try {
            await fetch(`${config.activateServer}:${config.activatePORT}/logout`, {
                method: 'POST',
                body: JSON.stringify({ token: token }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                mode: 'cors'
            });
        } catch (err) {
            console.log("ERROR " + err);
        }

        //  Clear storage
        sessionStorage.removeItem('authenticated');
        sessionStorage.removeItem('ACTIVATEtoken');
        //sessionStorage.removeItem('restorePage');
        // Set Logout   
        setLogout(true);
    }

    if (logout) {
        return <Navigate to="/login" />;
    }
    let currentView;
    let permissions = permissionService.getMyInformation();
    if (permissions === undefined) { permissions = {}; }

    switch (viewMode) {
        case "dashboard":
                currentView = <HealthProviderDashboard 
                switchView={switchView}
                permissions={permissionService}
                selectedOrganization={selectedOrganization}
                selectedOrganizationID={selectedOrganizationID}
                setSelectedTeamletID={setSelectedTeamletID}
                setSelectedOrganizationName={setSelectedOrganizationName}
                setHuddleID={setSelectedHuddleID}
                setMeetingID={setMeetingID}
                teamletName={teamletName}/>;
            break;
        case "configuration":
            currentView = <AdminConfiguration switchView={switchView} permissions={permissionService} />;
            break;
        case "about":
            currentView = <AboutPage switchView={switchView} username={username} />;
            break;
        case "help":
            currentView = <HelpPage switchView={switchView} username={username} />;
            break;
        case "datareadings":
            currentView = <DataReadingsManager permissions={permissionService} switchView={switchView} setSelectedPatientId={setSelectedPatientID}/>;
            break;
        case "huddledetails":
            currentView = <HuddleDetails permissions={permissionService} huddleID={huddleID} teamletID={teamletID} meetingID={meetingID} setHuddleID={setHuddleID} setMeetingID={setMeetingID} setSelectedPatientID={setSelectedPatientID} setSelectedTeamletID={setSelectedTeamletID} switchView={switchView} />;
            break;
        case "huddlehistory":
            currentView = <HuddleHistory permissions={permissionService} patientID={patientID} setHuddleID={setHuddleID} setMeetingID={setMeetingID} setSelectedTeamletID={setSelectedTeamletID} switchView={switchView} />;
            break;
        case "patientpanel":
            currentView = <PatientPanel setMeetingID={setMeetingID} setHuddleID={setHuddleID} permissions={permissionService} switchView={switchView} roleName={roleName} setSelectedPatientID={setSelectedPatientID} />;
            break;
        case "pathwaySettings":
            currentView = <PathwaySettings switchView={switchView} parentOrgID={parentOrgID} orgName={selectedOrganization} />;
            break;
        case "patientInformation":
            currentView = <PatientInformation permissions={permissionService} switchView={switchView} patientID={patientID} parentOrgID={parentOrgID} setSelectedTeamletID={setSelectedTeamletID} setHuddleID={setSelectedHuddleID} setMeetingID={setMeetingID}/>;
            break;
        case "addVitalData":
            currentView = <AddVitalData switchView={switchView} orgID={selectedOrganizationID} orgName={selectedOrganization} parentOrgID={parentOrgID} parentOrgName={parentOrgName} />;
            break;
        case "MyProfile":
            currentView = <MyProfile 
                            setSelectedTeamletID={setSelectedTeamletID} 
                            setSelectedPatientID={setPatientID} 
                            permissions={permissionService} 
                            switchView={switchView} 
                            setHuddleID={setSelectedHuddleID} 
                            setMeetingID={setMeetingID}
                            setReturnToView={setReturnToView}/>;
            break;
        case "patientinformationEdit":
            currentView = <PatientInformationEdit permissions={permissionService} switchView={switchView} patientID={patientID} />;
            break;
        case "patientvitalshistory":
            currentView = <PatientInformationVitalsHistory permissions={permissionService} switchView={switchView} patientID={patientID} />;
            break;
        case "patientvitalthresholds":
            currentView = <PatientVitalThresholds switchView={switchView} patientID={patientID} permissions={permissionService} />;
            break;
        case "TeamletDetails":
            currentView = <TeamletDetails 
                            setSelectedTeamletID={setSelectedTeamletID} 
                            setSelectedPatientID={setPatientID} 
                            switchView={switchView} 
                            permissions={permissionService} 
                            setHuddleID={setSelectedHuddleID} 
                            setMeetingID={setMeetingID} 
                            teamletID={teamletID} 
                            setReturnToView={setReturnToView}/>;
            break;
        case "editTeamlet":
            currentView = <EditTeamlet
                returnTo={returnTo}
                switchView={switchView}
                teamletID={teamletID} />;
            break;
        case "addHuddle":
            currentView = <AddHuddle
                            switchView={switchView}
                            teamletID={teamletID}
                            editMode='add'/>;
            break;
        case "editHuddle":
            currentView = <AddHuddle
                            switchView={switchView}
                            teamletID={teamletID}
                            editMode='edit'
                            meetingID={meetingID}/>;
            break;
        case "huddleDetails":
            currentView = <HuddleDetails
                switchView={switchView}
                huddleID={huddleID}
                meetingID={meetingID}
                permissions={permissionService}
                setMeetingID={setMeetingID}
                teamletID={teamletID}
                setHuddleID={setHuddleID}
                setSelectedPatientID={setSelectedPatientID}
                setSelectedTeamletID={setTeamletID}/>;
                break;
        case "rpmdevices":
            currentView = <RPMdeviceModals switchView={switchView} />;
            break;
        case 'patientRegistration':
            currentView = <PatientRegistrationView
                switchView={switchView} />;
            break;
        case 'patientRegistrationSelect':
            currentView = <PatientRegistrationSelect
                switchView={switchView}
                setSelectedPatientID={setSelectedPatientID}/>;
            break;
        case 'patientRegistrationAdd':
            currentView = <PatientRegistrationAdd
                switchView={switchView}
                patientID={patientID} />;
            break;
    }

   return (
        <div>
            <Navbar
                expandCollapseSidebar={expandCollapseSidebar}
                roleName={roleName}
                displayRoleName={displayRoleName}
                username={username}
                switchView={switchView}
                organizationList={organizationList}
                selectedOrganization={selectedOrganization}
                selectedOrganizationID={selectedOrganizationID}
                setSelectedOrganization={setSelectOrganization} />
            <div className="app-body-content">
                <Sidebar
                    expanded={sidebarExpanded}
                    switchView={switchView}
                    permissions={permissionService}
                    defaultView={viewMode} />
                <div className="page-content">
                    {currentView}
                </div>
            </div>

            <Timeout logout={Logout} />
        </div>
    );
}

export default HealthProviderContainer;

